import {
  AttributeModel,
  MessageStatus,
  MessageType,
  PackCategory,
  PlanCategory,
  ProductCategory,
  ProductModel,
  ProductModelType,
} from '@/models/api';
import { Cart, CartModel } from './cart';
import { Instance, destroy, getParent, types } from 'mobx-state-tree';
import _, { keyBy, sumBy } from 'lodash';

import { DISCOUNTS } from '@/shared/constant';
import { findLineItemByPositionAndDay, showToast } from '@/utils/helpers';
import { toast } from 'react-toastify';
import { withRootStore } from '../helpers/with-root-store';
import {
  CATEGORY_MAP,
  DEFAULT_ERROR_MESSAGE,
  ToastEnum,
} from '@/utils/constants';
import {
  trackMealPlanUpdated,
  trackProductAdded,
  trackProductRemoved,
} from '@/utils/track/tracker.helper';

type MacroType = 'calories' | 'carbs' | 'protein' | 'fats' | 'fibre';

const calMacroItems = (macro: MacroType, items: LineItem[]): number =>
  _.sumBy(items, (item) => _.get(item, `product.nutritionalInfo.${macro}`, 0));

export const PositionModel = types
  .model('Position')
  .props({
    x: types.number,
    y: types.number,
    scrollPosition: types.optional(types.number, 0),
    routerPathname: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setX(x: number) {
      self.x = x;
    },
    setY(y: number) {
      self.y = y;
    },
    setScrollPosition(scrollPosition: number, routerPathname = '') {
      self.scrollPosition = scrollPosition;
      self.routerPathname = routerPathname;
    },
  }));

export interface Position extends Instance<typeof PositionModel> {}

export const LineItemModel = types
  .model('LineItem')
  .props({
    sku: types.reference(types.late(() => ProductModel)),
    qty: types.number,
    attributes: types.optional(types.array(AttributeModel), []),
  })
  .views((self) => ({
    get product() {
      return self.sku;
    },
    get image() {
      return this.product.image;
    },
    get name() {
      return this.product.name;
    },
    get position(): number {
      return _.find(self.attributes, { name: 'position' })?.value ?? 0;
    },
    get day(): number {
      return _.find(self.attributes, { name: 'day' })?.value ?? 0;
    },
  }));

export interface LineItem extends Instance<typeof LineItemModel> {}

export const OrderPlanModel = types
  .model('OrderPlan')
  .props({
    planId: types.identifier,
    category: types.enumeration(Object.values(PlanCategory)),
    subCategory: types.maybe(types.frozen()),
    name: types.string,
    image: types.union(types.undefined, types.null, types.string),
    netPrice: types.optional(types.number, 0),
    attributes: types.optional(types.array(AttributeModel), []),
    lineItems: types.optional(types.array(LineItemModel), []),
    selectedItemPosition: types.optional(PositionModel, { x: 0, y: 0 }),
    hasScrolled: types.optional(types.boolean, false),
    planRoute: types.maybe(types.string),
  })
  .extend(withRootStore)
  .views((self) => ({
    get isWlpPlan(): boolean {
      return (
        self.category === PlanCategory.WLP ||
        self.subCategory?.toLowerCase()?.startsWith('wlp')
      );
    },
    get numberOfItems(): number {
      return sumBy(self.lineItems, 'qty');
    },
    get numberOfDiscountedItems(): number {
      if (this.isWlpPlan) return 0;
      return sumBy(self.lineItems, (item) =>
        item.product.isDiscountable ? item.qty : 0,
      );
    },
    get numberOfDays(): number {
      return _.find(self.attributes, { name: 'numberOfDays' })?.value ?? 0;
    },
    get goal(): string | undefined {
      return _.find(self.attributes, { name: 'goal' })?.value;
    },
    get productQtyBySku(): Record<string, LineItem> {
      return keyBy(self.lineItems, (item) => item.product.sku);
    },
    get allSkus(): string[] {
      return self.lineItems.map((item) => item.product.sku);
    },
    get parent(): Cart {
      return getParent<typeof CartModel>(self, 2);
    },
    get isSavedTimePlan(): boolean {
      return self.subCategory === PackCategory.SAVE_TIME;
    },
    get discount(): number {
      const cart: Cart = this.parent;
      if (this.isWlpPlan)
        return _.round(((this.wasTotal - this.total) * 100) / this.wasTotal);
      return cart?.discount || 0;
    },
    get total(): number {
      if (this.isWlpPlan) return self.netPrice;
      const discount = this.discount;
      return _.round(
        sumBy(self.lineItems, ({ product, qty }) => {
          return product.getDiscountedPrice(discount) * qty;
        }),
        2,
      );
    },
    get wasTotal(): number {
      return _.round(
        sumBy(self.lineItems, ({ product, qty }) => {
          if (!product.netPrice) return 0;
          return product?.netPrice * qty;
        }),
        2,
      );
    },
    get lineItemSubTotal(): number {
      if (this.isWlpPlan) return self.netPrice;
      return _.round(
        sumBy(self.lineItems, ({ product, qty }) => {
          if (!product.netPrice) return 0;
          return product.netPrice * qty;
        }),
        2,
      );
    },
    get promotionTotal(): number {
      return _.round(this.lineItemSubTotal - this.total, 2);
    },
    get discountForPacks(): number {
      const thresholdDiscounts = Object.values(DISCOUNTS);
      const thresholds = _.map(thresholdDiscounts, 'threshold');
      const totalDiscountedItems =
        this.numberOfDiscountedItems +
        self.rootStore.cartStore.cart.numberOfDiscountedItems;
      const index = _.findLastIndex(
        thresholds,
        (o: number): boolean => o <= totalDiscountedItems,
      );

      return _.nth(thresholdDiscounts, index)?.value ?? 0;
    },
    get cheapestMealPriceWithDiscount(): number {
      const discount = this.discountForPacks;
      const meals = _.filter(
        self.lineItems,
        (item) => item.product.displayCategory === ProductCategory.MEALS,
      );
      const cheapestItem = _.minBy(meals, (item) =>
        item.product.getDiscountedPrice(discount),
      );
      return cheapestItem?.product.getDiscountedPrice(discount) || 0;
    },
    get activeLevels(): number[] {
      return self.attributes.find((att) => att.name === 'activeLevels')
        ?.value as number[];
    },
    get exceededLimits() {
      const limits = _.filter(
        self.rootStore.productStore.productRestrictionLimits,
        (limit) => {
          const quantity = _.sumBy(self.lineItems, (item) =>
            limit.containsProduct(item.product.sku) ? item.qty : 0,
          );
          return quantity >= limit.quantityLimit;
        },
      );
      return limits;
    },
    get config(): string[] {
      return _.find(self.attributes, { name: 'config' })?.value || [];
    },
    get mealPositions(): number[] {
      return _.compact(
        _.map(this.config, (item, index) => (item === 'meals' ? index : null)),
      );
    },
    get isValid(): boolean {
      if (self.category === PlanCategory.PACKS) {
        const mealCounts = _.sumBy(self.lineItems, (item) => {
          return this.mealPositions.includes(item.position) ? 1 : 0;
        });
        return mealCounts === this.mealPositions.length * this.numberOfDays;
      }
      return true;
    },
    get categorySummary() {
      const lineItemGroups = _.groupBy(self.lineItems, (item) =>
        _.get(CATEGORY_MAP, item.product.displayCategory || ''),
      );
      return _.map(_.entries(lineItemGroups), ([category, items]) => ({
        category,
        total: _.sumBy(items, (item) => item.qty),
        items,
      }));
    },
    getCategoryCount(category: ProductCategory): number {
      return _.filter(
        self.lineItems,
        (item) => item.product.displayCategory === category,
      ).length;
    },
    getItemsByDay(day: number): LineItem[] {
      return _.filter(self.lineItems, (o: LineItem) => o.day === day);
    },
    getItemsByPositionAndDay(
      position: number,
      day: number,
    ): LineItem[] | undefined {
      // @ts-ignore
      return _.find(
        self.lineItems,
        (o: LineItem) => o.day === day && o.position === position,
      );
    },
    findAttribute(name: string) {
      return _.find(self.attributes, { name })?.value;
    },
    findLineItemBySku(sku?: string): LineItem | undefined {
      return _.find(self.lineItems, (item) => item.product.sku === sku);
    },
    getNutritionByDay(day: number) {
      const items = this.getItemsByDay(day);
      const cal = calMacroItems('calories', items).toFixed(0);
      const c = calMacroItems('carbs', items).toFixed(1);
      const p = calMacroItems('protein', items).toFixed(1);
      const f = calMacroItems('fats', items).toFixed(1);
      return { cal, c, p, f };
    },
  }))
  .actions((self) => ({
    increaseLineItem(
      product: ProductModelType,
      quantity = 1,
      location?: string,
      successMessage?: string,
    ) {
      // check if the target product is restricted
      if (self.rootStore.productStore.isProductRestricted(product.sku)) {
        self.rootStore.generalStore.setRestrictedProductsCheck([product.sku]);
        return;
      }
      // check if the target product reach the limit
      const lineItem = self.lineItems.find(
        (item) => item.product.sku === product.sku,
      );
      const limit = _.find(self.exceededLimits, (limit) =>
        limit.containsProduct(product.sku),
      );
      if (limit) {
        const messageRto = {
          message: limit?.errorMessage,
          status: MessageStatus.INFO,
          title: 'Limit reached',
          type: MessageType.TOAST,
        };
        self.rootStore.generalStore.createNotification(messageRto);
        return;
      }
      if (lineItem) {
        const orderLimit = product?.cms?.orderQuantityLimit;
        if (orderLimit) {
          const currentQty = self.productQtyBySku[product.sku]?.qty || 0;
          if (currentQty >= orderLimit) {
            const errMessage = `Order limit for this item is ${orderLimit}.`;
            showToast(errMessage, ToastEnum.INFO, 'order-limit-toast');
            return;
          }
        }
        lineItem.qty += quantity;
        try {
          trackProductAdded(
            product,
            location || '',
            self.rootStore.cartStore.cart.cartId,
          );
        } catch (error) {
          // ignore error
        }
      } else {
        const lineItem = LineItemModel.create({
          sku: product.sku,
          qty: quantity,
        });
        self.lineItems.push(lineItem);
        try {
          trackProductAdded(
            product,
            location || '',
            self.rootStore.cartStore.cart.cartId,
          );
        } catch (error) {
          // ignore error
        }
      }
      if (successMessage) {
        showToast(successMessage, ToastEnum.INFO, 'increase-line-item-success');
      }
    },
    decreaseLineItem(product: ProductModelType, location: string) {
      const lineItem = self.lineItems.find(
        (item) => item.product.sku === product.sku,
      );
      if (lineItem) {
        lineItem.qty -= 1;
        try {
          trackProductRemoved(
            product,
            location || '',
            self.rootStore.cartStore.cart.cartId,
          );
        } catch (error) {
          // ignore error
        }
        if (lineItem.qty <= 0) {
          destroy(lineItem);
        }
      }
    },
    deleteLineItem(product: ProductModelType, location?: string) {
      const lineItem = self.lineItems.find(
        (item) => item.product.sku === product.sku,
      );
      if (lineItem) {
        destroy(lineItem);
        try {
          trackProductRemoved(
            product,
            location || '',
            self.rootStore.cartStore.cart.cartId,
          );
        } catch (error) {
          // ignore error
        }
      }
    },
    addProducts(products: ProductModelType[], location?: string) {
      products.forEach((product) => {
        this.increaseLineItem(product, 1, location);
      });
    },
    swapLineItem(product: ProductModelType, position: number, day: number) {
      // check if the target product is restricted
      if (self.rootStore.productStore.isProductRestricted(product.sku)) {
        self.rootStore.generalStore.setRestrictedProductsCheck([product.sku]);
        throw new Error('Product is restricted');
      }
      // check if the target product reach the limit
      const limit = _.find(self.exceededLimits, (limit) =>
        limit.containsProduct(product.sku),
      );
      if (limit) {
        const messageRto = {
          message: limit?.errorMessage,
          status: MessageStatus.INFO,
          title: 'Limit reached',
          type: MessageType.TOAST,
        };
        self.rootStore.generalStore.createNotification(messageRto);
        throw new Error('Limit reached');
      }

      const lineItem = findLineItemByPositionAndDay(
        position,
        day,
        self.lineItems,
      );
      if (lineItem) {
        lineItem.sku = product;
        try {
          trackMealPlanUpdated(self);
        } catch (error) {
          // ignore error
        }
      } else {
        const lineItem = LineItemModel.create({
          sku: product.sku,
          qty: 1,
          attributes: [
            { name: 'position', value: position },
            { name: 'day', value: day },
          ],
        });
        self.lineItems.push(lineItem);
        try {
          trackMealPlanUpdated(self);
        } catch (error) {
          // ignore error
        }
      }
    },
    swapProduct(
      newProduct: ProductModelType,
      originalProduct?: ProductModelType,
    ) {
      // don't swap if the new product is the same as the original product
      if (newProduct.sku === originalProduct?.sku) {
        return;
      }
      // check if the target product is restricted
      if (self.rootStore.productStore.isProductRestricted(newProduct.sku)) {
        self.rootStore.generalStore.setRestrictedProductsCheck([
          newProduct.sku,
        ]);
        throw new Error('Product is restricted');
      }

      // check if the target product reach the limit
      const limit = _.find(self.exceededLimits, (limit) =>
        limit.containsProduct(newProduct.sku),
      );
      if (limit) {
        const messageRto = {
          message: limit?.errorMessage,
          status: MessageStatus.INFO,
          title: 'Limit reached',
          type: MessageType.TOAST,
        };
        self.rootStore.generalStore.createNotification(messageRto);
        throw new Error('Limit reached');
      }
      // find the lineItem with the new product
      const newLineItem = self.lineItems.find(
        (item) => item.product.sku === newProduct.sku,
      );

      // find the lineItem with the original product
      const originalLineItem = self.lineItems.find(
        (item) => item.product.sku === originalProduct?.sku,
      );
      if (originalLineItem) {
        if (newLineItem) {
          newLineItem.qty += originalLineItem.qty;
          destroy(originalLineItem);
        } else {
          originalLineItem.sku = newProduct;
        }
      } else {
        if (newLineItem) {
          newLineItem.qty += 1;
        } else {
          const lineItem = LineItemModel.create({
            sku: newProduct.sku,
            qty: 1,
          });
          self.lineItems.push(lineItem);
        }
      }
    },
    removeLineItem(position: number, day: number) {
      const lineItem = findLineItemByPositionAndDay(
        position,
        day,
        self.lineItems,
      );
      if (lineItem) {
        try {
          trackProductRemoved(
            lineItem.product,
            '',
            self.rootStore.cartStore.cart.cartId,
          );
          if (!_.isNil(position)) {
            trackMealPlanUpdated(self);
          }
        } catch (error) {
          // ignore error
        }
        destroy(lineItem);
      }
    },
    mergeLineItem(lineItem: LineItem) {
      // check if the target product reach the limit
      const existingLineItem = self.lineItems.find(
        (item) => item.product.sku === lineItem.product.sku,
      );
      if (existingLineItem) {
        existingLineItem.qty += lineItem.qty;
      } else {
        const newLineItem = LineItemModel.create({
          sku: lineItem.product.sku,
          qty: lineItem.qty,
        });
        self.lineItems.push(newLineItem);
      }
    },
    setXandYPosition(position: number, day: number) {
      self.selectedItemPosition.setX(position);
      self.selectedItemPosition.setY(day);
    },
    setScrollPosition(position: number, routerPathname = '') {
      self.selectedItemPosition.setScrollPosition(position, routerPathname);
    },
    setHasScrolled(hasScrolled: boolean) {
      self.hasScrolled = hasScrolled;
    },
    fillItemAtX(category: ProductCategory, x: number, y: number) {
      const lineItem = findLineItemByPositionAndDay(x, y, self.lineItems);
      if (!lineItem) {
        const product =
          self.rootStore.productStore.getRandomProductByCategory(category);
        if (product) {
          this.swapLineItem(product, x, y);
        }
      }
    },
    removeItemAtX(x: number, y: number) {
      const lineItem = findLineItemByPositionAndDay(x, y, self.lineItems);
      if (lineItem) {
        destroy(lineItem);
      }
    },
    fillAllItemsAtXForEveryDay(
      category: ProductCategory,
      x: number,
      day: number,
    ) {
      for (let i = 1; i <= day; i++) {
        this.fillItemAtX(category, x, i);
      }
    },
    clearAllItemsAtXForEveryDay(x: number, day: number) {
      for (let i = 1; i <= day; i++) {
        this.removeItemAtX(x, i);
      }
    },
    setPlanRoute(planRoute: string) {
      let sanitizedRoute = planRoute.replace(/^\/|\/$/g, '');
      sanitizedRoute = `/${sanitizedRoute}/`;
      self.planRoute = sanitizedRoute;
    },
    setActiveLevel(index: number, level: number) {
      const activeLevelsAttribute = self.attributes.find(
        (att) => att.name === 'activeLevels',
      );
      if (!activeLevelsAttribute) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        return;
      }
      let newValue = [...activeLevelsAttribute.value] as Number[];
      newValue[index] = level;
      activeLevelsAttribute.value = newValue;
    },
  }));

export interface OrderPlan extends Instance<typeof OrderPlanModel> {}
